<template>
    <div>
        <div class="header-content">
            <h2 class="cs-title" v-if="data.title">
                <ColorSplit :data="data.title" />
            </h2>
            <h3 class="cs-sub-title" v-if="data.subTitle">{{data.subTitle}}</h3>
            <span class="cs-text" v-html="data.text"></span>
        </div>
        <div class="search-input">
            <input
                class="search-input__item"
                type="text"
                v-model="searchField"
            />
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0"/>
            </svg>
        </div>

        <div class="buttons">
            <button
                class="grey-button"
                v-for="(tag, tagKey) in data.tags"
                :key="tagKey"
                @click="filterTags(tag.title)"
            >
                {{ tag.title }}
            </button>
        </div>

        <div id="accordion" class="d-flex flex-column">
            <div 
                class="card" 
                v-for="(item, index) in faqs" 
                :key="index" 
                :id="`card${index}`" 
                @click="showBorder(index)"
            >
                <div
                    class="card-header"
                    id="headingOne"
                    data-toggle="collapse"
                    :data-target="`#collapseOne${index}`"
                    aria-expanded="true"
                    :aria-controls="`collapseOne${index}`"
                    @click="hideButton(index)"
                >
                    <div class="mb-0 d-flex justify-content-between align-items-center">
                        <span class="font-weight-bold p-0">
                            {{ item.title }}
                        </span>
                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" class="arrow">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M2.79395 5.57819L7.79395 10.5782L12.7939 5.57819L2.79395 5.57819Z"/>
                        </svg>
                        <span class="dash"></span>
                    </div>
                </div>

                <div
                    :id="`collapseOne${index}`"
                    class="collapse"
                    aria-labelledby="headingOne"
                    data-parent="#accordion"
                >
                    <div class="card-body">
                        <span v-html="item.answer"></span>
                        <div class="buttons">
                            <button
                                v-for="(tag, idx) in item.tags"
                                :key="idx"
                                class="tag-button"
                            >
                                {{ tag.title }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CSFAQ',
    components: {
        ColorSplit: () => import("../components/CSUtilsColorSplit.vue"),
    },
    data() {
        return {
            faqs: [],
            searchField: ''
        }
    },
    props: {
        data: {
            type: Object,
            default: () => {}
        }
    },
    mounted() {
        this.faqs = this.data?.faqs && typeof this.data.faqs == 'object' ? Object.values(this.data.faqs) : [];
        console.log(this.data)
    },
    methods: {
        hideButton(index) {
            const cardHeader = document.querySelector(`#headingOne[data-target="#collapseOne${index}"]`);
            if (!cardHeader.classList.contains('collapsed')) {
                cardHeader.classList.toggle('hide-button');
            } 
        },
        showBorder(index){
            const cardHeader = document.querySelector(`#headingOne[data-target="#collapseOne${index}"]`);
            const card = document.querySelector(`#card${index}`);
            if (!cardHeader.classList.contains('collapsed')) {
                card.classList.toggle('bordered')
            }

        },
        stripHtmlTags(html) {
            // regular expression for removing html tags
            return html.replace(/<[^>]*>/g, '');
        },
        filterTags(query) {
            const lowerCaseQuery = query.toLowerCase().trim();
            const arrayTags = this.data?.tags && typeof this.data.tags == 'object' ? Object.values(this.data.tags) : [];
            const arrayFaqs = this.data?.faqs && typeof this.data.faqs == 'object' ? Object.values(this.data.faqs) : [];

            // filter tags that contain a partial match to the entered query
            const matchingTagIds = arrayTags
                .filter((t) => t.title.toLowerCase().includes(lowerCaseQuery))
                .map((t) => t._id);

            // filter faqs by tagId, title, or answer text (without HTML tags)
            console.log('Array faqs now:', arrayFaqs);
            this.faqs = arrayFaqs.filter((faq) => {
                const hasMatchingTag = Object.values(faq.tags).some((t) => matchingTagIds.includes(t._id.toString()));
                const hasMatchingTitle = faq.title.toLowerCase().includes(lowerCaseQuery);

                // remove html tags from answer
                const answerText = this.stripHtmlTags(faq.answer).toLowerCase();
                const hasMatchingAnswer = answerText.includes(lowerCaseQuery);

                return hasMatchingTag || hasMatchingTitle || hasMatchingAnswer;
            });
        }
    },
    watch: {
        searchField() {
            this.filterTags(this.searchField);
        }
    }
}
</script>

<style lang="scss" scoped>
    .header-content {
        text-align: center;
        margin-bottom: 30px;
        &:last-child {
            margin-bottom: 0;
        }
        .cs-title, .cs-sub-title {
            margin-bottom: 20px;
        }
        .cs-text {
            ::v-deep {
                * {
                    text-align: center;
                }
                img {
                    max-width: 100%;
                    height: 300px;
                    border-radius: 10px;
                    margin: 10px;
                }
                p,
                span {
                    font-weight: 400;
                    font-size: 14px;
                    color: #333333;
                }
                *:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .search-input {
        margin-top: 20px;
        display: block;
        position: relative;
        width: 100%;

        &__item {
            display: block;
            width: 100%;
            height: 40px;
            border: 1px solid #000;
            padding: 25px 15px 25px 40px;
            outline: none;
            border-radius: 30px;
            color: #000;
            background: transparent;
            &::placeholder {
                color: #8A8A8A;
            }
        }

        & svg {
            position: absolute;
            left: 2%;
            top: 50%;
            fill: #000;
            transform: translateY(-50%);
            cursor: pointer;
            width: 20px;
            height: 20px;
        }
    }
    .buttons {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin: 20px 0 !important;
        font-weight: 400;
        font-size: 14px;

        .grey-button,
        .tag-button {
            background-color: #E4EEF7;
            color: #8A8A8A;
            border: none;
            padding: 10px 20px;
            border-radius: 25px;
            transition: all 300ms ease-out;

            &:hover {
                opacity: .7;
            }
        }
    }
    .arrow {
        fill: #000;
    }
    .card {
        border-radius: 0;
        border: none;
        background: transparent;
        &.bordered {
            border: 1px solid #000;
            border-radius: 25px;
            margin-bottom: 5px;
            .card-header {
                border: none;
            }
            .card-body {
                border-radius: 25px;
            }
        }
    }
    .card-header {
        border-radius: 25px;
        margin-bottom: 10px;
        border: 1px solid #000;
        padding: 15px 15px 15px 20px;
        background-color: transparent;
        cursor: pointer;
        &.hide-button {
            .arrow {
                display: none;
            }
            .dash {
                display: block;
            }
        }
    }
    #accordion {
        margin-top: 30px;
    }
    .card-body {
        padding: 20px 20px 20px 30px;
        span {
            ::v-deep {
                color: #333333;
                img {
                    max-width: 100%;
                    height: 300px;
                    border-radius: 10px;
                    margin: 10px 10px 10px 0;
                    object-fit: cover;
                    object-position: center;
                }
                &:last-child {
                    margin: 0;
                }
            }
        }
    }
    .dash {
        height: 4px;
        width: 14px;
        background-color: #000;
        display: none;
    }
</style>
