import { render, staticRenderFns } from "./CSFaq.vue?vue&type=template&id=1ca783fc&scoped=true&"
import script from "./CSFaq.vue?vue&type=script&lang=js&"
export * from "./CSFaq.vue?vue&type=script&lang=js&"
import style0 from "./CSFaq.vue?vue&type=style&index=0&id=1ca783fc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca783fc",
  null
  
)

export default component.exports